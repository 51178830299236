import React from 'react'
import { navigateTo } from 'gatsby-link'
import classes from './prestations.module.css'

export default () => (
    <div className={classes.ListenContainer}>
        <div style={{
            maxWidth: 800,
            margin: 'auto',
            padding: '40px 20px',
            minHeight: 320,
            display: 'flex',
            alignItems:'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <h1 style={{textAlign: 'center'}}>Nous sommes à votre écoute</h1>
            <p style={{textAlign: 'center'}}>
                Nos conseillères et conseillers travaillent de manière confidentielle et adaptent leur approche à vos besoins individuels.  Votre thérapie commence par votre prise de rendez-vous qui est le premier pas vers le changement progressif.

            </p>
            <Butt />
        </div>
    </div>
)

const Butt = () => (
    <button
        className={classes.Button}
        onClick={() => navigateTo('/reservation')}>PRENDRE RENDEZ-VOUS
    </button>
)