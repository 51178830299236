import React, { Component } from 'react'
import classes from '../layouts/Pages.module.css'
import Footer from '../components/Footer/Footer'
import Listen from '../components/Contents/Prestations/Listen'
import Helmet from 'react-helmet'
import Layout from '../layouts'


const iconColor = "#4B53BC"

export default class extends Component {
    state = {
        activeTab: 'none'
    }
    openTab = e => {
        const tab = e.currentTarget.getAttribute('data-key')
        console.log('tab:', tab)
        if(tab === this.state.activeTab) {
            this.setState({activeTab: 'none'})
        }
        else {
            this.setState({activeTab: tab})
        }
    }
    render() {
        const { activeTab } = this.state
        return (
            <Layout location={this.props.location}>
                <Helmet>
                    <title>Prestations | Psy-Scan Institute</title>
                    <meta name="description" content="Nous nous spécialisons dans troubles psychiques, comportementaux et émotionnels chez les: enfants, adolescents et adultes. Vous pouvez réserver une consulation en ligne ou appeler le 032 721 49 33."/>
                </Helmet>
                <Mozaique>
                    <div style={{textAlign: 'center', padding: 20}}>
                        {questions.map( (item, index) => (
                            <div className={classes.questionItem} key={index}>{item}</div>
                        ))}
                    </div>
                </Mozaique>
                <div style={{maxWidth: 1170, padding: '40px 15px', margin: 'auto'}}>
                    <h1 style={{textAlign: 'center'}}>Nos Prestations</h1>
                    <h4 style={{textAlign: 'center'}}>Nous nous spécialisons dans les troubles psychiques, comportementaux et émotionnels chez les:</h4>
                    <div className={classes.TherapiesWrapper}>
                        <div className={activeTab === 'enfants' ? [classes.Enfants, classes.Active].join(' ') : classes.Enfants} onClick={this.openTab} data-key="enfants">
                            Enfants
                            { activeTab === 'enfants'
                                ?<div className={classes.PrestationArrow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill={iconColor} width="100" height="100" viewBox="0 0 24 24">
                                        <path d="M7 10l5 5 5-5z"/>
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                </div>
                                : null}
                        </div>
                        <div className={classes.MobilePrestationsWrapper}>
                            { activeTab === 'enfants'
                                ? <PrestationEnfant />
                                : null}
                        </div>
                        <div className={activeTab === 'ados' ? [classes.Ado, classes.Active].join(' ') : classes.Ado} onClick={this.openTab} data-key="ados">
                            Adolescents
                            { activeTab === 'ados'
                                ?<div className={classes.PrestationArrow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill={iconColor} width="100" height="100" viewBox="0 0 24 24">
                                        <path d="M7 10l5 5 5-5z"/>
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                </div>
                                : null}
                        </div>
                        <div className={classes.MobilePrestationsWrapper}>
                            { activeTab === 'ados'
                                ? <PrestationAdos />
                                : null}
                        </div>
                        <div className={activeTab === 'adultes' ? [classes.Adulte, classes.Active].join(' ') : classes.Adulte} onClick={this.openTab} data-key="adultes">
                            Adultes
                            { activeTab === 'adultes'
                                ?<div className={classes.PrestationArrow}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill={iconColor} width="100" height="100" viewBox="0 0 24 24">
                                        <path d="M7 10l5 5 5-5z"/>
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                </div>
                                : null}
                        </div>
                        <div className={classes.MobilePrestationsWrapper}>
                            { activeTab === 'adultes'
                                ? <PrestationAdultes />
                                : null}
                        </div>
                    </div>
                    <div className={classes.DesktopPrestationsWrapper}>
                        { activeTab === 'enfants'
                            ? <PrestationEnfant />
                            : null}

                        { activeTab === 'ados'
                            ? <PrestationAdos />
                            : null}

                        { activeTab === 'adultes'
                            ? <PrestationAdultes />
                            : null}
                    </div>
                </div>
                <Listen/>
                <Footer/>
            </Layout>
        )
    }
}

const questions = ["Personne ne peut comprendre ce que je ressens",
    "Si je commence à pleurer, je ne pourrai plus m'arrêter",
    "Je suis tellement bon à rien que personne ne saurait m'aimer",
    "Je dois frapper quelque chose, sinon je vais exploser",
    "Si les gens savaient ce qui se passait dans ma tête, ils me croiraient fou",
    "En dépit de mes meilleurs efforts, je n'arrive plus à comprendre, comme je le faisais avant",
    "Ma famille me rend fou!",
    "Plus rien ne me plaît",
    "Si seulement je pouvais cesser de ressentir"]


const Mozaique = ({ children}) => (
    <div className={classes.PrestationHero} style={{
        boxShadow: "inset 0 0 0 10000px rgba(0,0,0,.65)",
        backgroundSize: "cover",

    }}>
        {children}
    </div>
)


const PrestationEnfant = () => (
    <div className={classes.PrestationWrapper}>
        <ul className={classes.PrestationSide}>
            <li>Troubles de l'attachement et traumatisme de la naissance</li>
            <li>Troubles d'attachements et abus sexuels, émotionnels, physiques et négligences</li>
            <li>Troubles du sommeil chez l'enfant</li>
            <li>Troubles de l'opposition (TO)</li>
            <li>Troubles de l'opposition avec provocation (TOP)</li>
            <li>Troubles de l'attention et de l'hyperactivité et du déficit de l'attention (THADA)</li>

        </ul>
        <ul className={classes.PrestationSide}>
            <li>Anxiété de performance et phobie scolaire</li>
            <li>Anxiété généralisée</li>
            <li>Troubles de l'humeur</li>
            <li>Troubles alimentaire</li>
            <li>Troubles du langage et des apprentissages</li>
            <li>Troubles sphinctériens</li>

        </ul>
    </div>
)

const PrestationAdos = () => (
    <div className={classes.PrestationWrapper}>
        <ul className={classes.PrestationSide}>
            <li>Troubles de conduites (TC)</li>
            <li>Troubles des conduites antisociales (TCA)</li>
            <li>Traumatisme, dissociation et troubles des apprentissages</li>
            <li>Comportements addictifs de l'adolescence</li>
            <li>Trouble de l'attention et de l'hiperactivité et du déficit de l'attention (THADA)</li>
            <li>Comportements addictifs de l'adolescence</li>
        </ul>
        <ul className={classes.PrestationSide}>
            <li>Kleptomanie et jeux pathologique</li>
            <li>Troubles obessessionnels compulsifs</li>
            <li>Troubles anxieux généralisés (TAG)</li>
            <li>Phobie sociale</li>
            <li>Troubles de l'humeur</li>
            <li>Troubles alimentaires (boulimie, anorexie)</li>
        </ul>
    </div>
)


const PrestationAdultes = () => (
    <div className={classes.PrestationWrapper}>
        <ul className={classes.PrestationSide}>
            <li>Troubles anxieux
                <ul className={classes.PrestationWrapperIn}>
                    <li>anxiété  généralisée, phobies sociales, phobies simples, attaques de panique, troubles obsessionels compulsifs, états de stress posttraumatiques, etc</li>
                    <li>troubles  de l'humeur</li>
                    <li>troubles de l'adaptation</li>
                    <li>dépression</li>
                    <li>troubles bipolaires</li>
                </ul>
            </li>
            <li>Troubles alimentaires
                <ul className={classes.PrestationWrapperIn}>
                    <li>anorexie</li>
                    <li>boulimie</li>
                </ul>
            </li>

        </ul>
        <ul className={classes.PrestationSide}>
            <li>Troubles somatoformes douloureux et dissociations</li>
            <li>Troubles des conduites
                <ul className={classes.PrestationWrapperIn}>
                    <li>violence</li>
                    <li>impulsivité</li>
                </ul>
            </li>
            <li>Troubles sexuels</li>
            <li>Troubles du sommeil non organiques</li>
        </ul>
    </div>
)









